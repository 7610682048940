// base react and gatsby imports
import React from "react"
import ImagineSpinner from "../components/spinner";

/**
 * @see gatsby-browser.js
 * @see signInToView
 * @see LoginForm
 * The presentation of the login form is actually handled above this component
 * The issue with presenting it here is that we would need to redirect if the user is logged in, 
 * however if we redirect at this level we end up just redirecting within the displayed Layout component.
 * So, we whould see the profile page, but we would have the AuthLayout surrounding it. The redirect now happens
 * within the Layout component. 
 */
const LoginPage = ({ user }) => {
  return ( <ImagineSpinner /> )
}

export default LoginPage;

